<template>
  <b-card ref="container">
      <b-card-title class="card-title p-1 m-0">
        <b-row class="mb-2">
          <b-col cols="12" class="d-flex justify-content-start">
            <button class="btn btn-sm button-primary mr-1" @click="onAdd">
              <feather-icon
                  size="15"
                  @click="onEdit(item)"
                  icon="PlusSquareIcon"
                  class="mr-1"
                />
              <span style="font-size: 15px">Agregar usuario</span>
            </button>
          </b-col>
        </b-row>
        <b-row class="row">
          <b-col cols="4" class="pl-2">
              <label class="mr-1">Mostrar</label
              >
              <select
                class="form-control form-control-sm"
                @input="onPagination($event)"
                style="width: 100px; display: inline-block !important;"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </b-col>
          <b-col cols="8">
            <filter-bar-component v-on:search="onSearch"></filter-bar-component>
          </b-col>
        </b-row>
      </b-card-title>
      <b-card-body class="card-body py-2 px-0">
        <full-table-component ref="fulltable" :perPage="perPage" :fields="fields" url="/users">
          <template slot="roles" slot-scope="props">
            <label class="badge" style="color: #7b7878; background-color: rgba(227, 228, 230, 0.8);">{{ rolesSeparated(props.rowData) }}</label>
          </template>
          <template slot="buttons-slot" slot-scope="props">
            <b-dropdown class="p-0" variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon
                  size="20"
                  icon="ListIcon"
                />
              </template>
              <b-dropdown-item @click="onView(props.rowData)">
                <feather-icon
                  size="20"
                  icon="SearchIcon"
                />
                Ver detalles
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(props.rowData)">
                <feather-icon
                  size="20"
                  icon="TrashIcon"
                />
                Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </full-table-component>
      </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FullTableComponent from "../../../layouts/components/FullTableComponent";
import FilterBarComponent from "../../../layouts/components/FilterBarComponent";
import UserService from "../../../services/UserService";
import AlertService from "../../../services/AlertService";
import UtilService from "../../../services/UtilService";
import UserEdit from "./UserEdit";
import UserView from "./UserView";
import { BRow, BCol, BCard, BCardTitle, BCardBody, BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  name: "UserAll",
  components: {
    FilterBarComponent,
    FullTableComponent,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      perPage: null,
      fields: [
        {
          name: "id",
          title: "Id",
          sortField: "id"
        },
        {
          name: "created_at",
          title: "Creado",
          sortField: "created_at",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "name",
          title: "Nombre",
          sortField: "name"
        },
        {
          name: "last_name",
          title: "Apellido",
          sortField: "last_name"
        },
        {
          name: "email",
          title: "Email",
          sortField: "email"
        },
        {
          name: "phone",
          title: "Telefono",
          sortField: "phone"
        },
        {
          name: "roles",
          title: "Roles",
          titleClass: "center aligned",
          dataClass: "center aligned"
        },
        {
          name: "status",
          title: "Estado",
          sortField: "status",
          formatter: value => {
            return UtilService.statusUser(value);
          }
        },
        {
          name: "dni",
          title: "Dni",
          sortField: "dni"
        },
        {
          name: "buttons-slot",
          title: "Acciones",
          titleClass: "text-right",
          dataClass: "text-right align-middle"
        }
      ]
    };
  },
  methods: {
    /**
     *
     */
    onPagination(event) {
      this.perPage = parseInt(event.target.value);
      this.$refs.fulltable.perPage = this.perPage;
    },
    /**
     * 
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    rolesSeparated(rowData) {
      let roles = "";
      rowData.roles.map(data => {
        roles += data.name + " ";
      });
      return roles;
    },
    statusBoolean(status) {
      if (status == "enabled") return true;
      else return false;
    },
    onAdd() {
      this.$modal.show(
        UserEdit,
        {
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            }
          }
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto"
        }
      );
    },
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el usuario con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        UserService.destroy(item)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch(error => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async onView(item) {
      this.$modal.show(
        UserView,
        {
          user: item
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto"
        }
      );
    },
    async onEdit() {}
  },
  computed: {},
  mounted() {
    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open
      });
    }
    if (!UserService.isAdminOrStaff()) {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>

.card-body{
  padding: 0 !important;
}

</style>
