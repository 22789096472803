var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-3"},[_c('div',{staticClass:"card-body card-modal pt-0"},[_c('div',{staticStyle:{"height":"500px"}},[_c('h3',{staticClass:"title"},[_vm._v("Agregar Usuario")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('validation-observer',{ref:"userEdit"},[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"E-mail","description":"Email Electronico"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.edit)?_c('b-form-group',{attrs:{"label":"Contraseña","description":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"password","state":errors.length > 0 ? false:null,"autocomplete":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1861026837)})],1):_vm._e(),(!_vm.edit)?_c('b-form-group',{attrs:{"label":"Confirmar Contraseña"}},[_c('validation-provider',{attrs:{"name":"Confirmar Contraseña","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"password","state":errors.length > 0 ? false : null,"name":"user-password-confirmation","autocomplete":"off"},model:{value:(_vm.user.c_password),callback:function ($$v) {_vm.$set(_vm.user, "c_password", $$v)},expression:"user.c_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4092397193)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"CUIT / DNI"}},[_c('validation-provider',{attrs:{"name":"DNI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"dni","placeholder":"00000000A","state":errors.length > 0 ? false:null,"autocomplete":"off","disabled":_vm.edit},model:{value:(_vm.user.dni),callback:function ($$v) {_vm.$set(_vm.user, "dni", $$v)},expression:"user.dni"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Apellido"}},[_c('validation-provider',{attrs:{"name":"Apellido","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"last_name","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Telefono"}},[_c('validation-provider',{attrs:{"name":"Telefono","rules":"max_value:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","type":"text","name":"phone","state":errors.length > 0 ? false:null,"autocomplete":"off"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Roles"}},[_c('validation-provider',{attrs:{"name":"Envios Mensuales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","name":"roles","options":_vm.roles,"multiple":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])])]),_c('footer',{staticClass:"pt-2 px-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm"},on:{"click":_vm.onSubmit}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }