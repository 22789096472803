<template>
  <div class="m-3">
    <div>
      <h3 class="title">DETALLE DE USUARIO</h3>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12" v-if="view === 'show'">
          <div class="card mx-8 p-2">
            <div class="card-body p-3">
              <RowComponent :title="'ID:'" :content="user.id.toString()" />
              <RowComponent :title="'NOMBRE:'" :content="user.name" />
              <RowComponent :title="'DNI:'" :content="user.dni" />
              <RowComponent :title="'APELLIDO:'" :content="user.last_name" />
              <RowComponent :title="'EMAIL:'" :content="user.email" />
              <RowComponent :title="'TELEFONO:'" :content="user.phone" />
              <div>
                <p>
                  <b>ROLES :</b>
                  <label
                    class="badge badge-primary mx-1"
                    v-bind:key="item.id"
                    v-for="item in  user.roles"
                  >{{ item.name }}</label>
                </p>
              </div>

              <RowComponent :title="'STATUS:'" :content="user.status" />
              <div>
                <p>
                  <b>CREADO :</b>
                  <span>{{user.created_at | date_format}}</span>
                </p>
              </div>
              <div>
                <p>
                  <b>ACTUALIZADO :</b>
                  <span>{{user.updated_at | date_format}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12" v-else>
          <user-change-password :user="user" @onClose="onCloseChange"></user-change-password>
        </div>
      </div>
    </div>

    <footer>
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 float-right" @click="onClose">Cerrar</b-button>
          <b-button
            v-if="view === 'show' && user.id !== 1 && isAdminOrStaff"
            size="sm"
            class="mr-1 float-right"
            variant="danger"
            @click="onChange"
          >Cambiar Contraseña</b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserChangePassword from "./UserChangePassword";
import UserService from "../../../services/UserService";
import { BButton } from 'bootstrap-vue'

export default {
  name: "UserView",
  props: {
    user: Object
  },
  components: {
    BButton,
    UserChangePassword
  },
  data() {
    return {
      view: "show",
      isAdmin: UserService.isAdmin(),
      isAdminOrStaff: UserService.isAdminOrStaff()
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onChange() {
      this.view = "change";
    },
    onCloseChange($event) {
      this.view = "show";
      if ($event.change) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Actualizar`,
            text: `Contraseña actualizada correctamente`,
            icon: "InfoIcon",
            variant: "info",
          },
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
