
<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <div style="height: 500px;">
        <h3 class="title">Agregar Usuario</h3>
        <div class="row justify-content-center">
          <div class="col-12">
            <validation-observer
              ref="userEdit"
            >

            <b-form @submit="onSubmit">

              <!-- Email -->
              <b-form-group label="E-mail" description="Email Electronico">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                <b-form-input
                  size="sm"
                  type="text"
                  v-model="user.email"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Contraseña -->
              <b-form-group label="Contraseña" description="Contraseña" v-if="!edit">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:5"
                >
                <b-form-input
                  size="sm"
                  type="password"
                  v-model="user.password"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="password"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Repetir Contraseña -->
              <b-form-group
                label="Confirmar Contraseña"
                v-if="!edit"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Contraseña"
                  rules="required|confirmed:password"
                >
                  <b-form-input
                    size="sm"
                    v-model="user.c_password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    name="user-password-confirmation"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- CUIT / DNI -->
              <b-form-group label="CUIT / DNI">
                <validation-provider
                  #default="{ errors }"
                  name="DNI"
                  rules="required"
                >
                <b-form-input
                  size="sm"
                  type="text"
                  name="dni"
                  placeholder="00000000A"
                  v-model="user.dni"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                  :disabled="edit"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Nombre -->
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required|max:255"
                >
                <b-form-input
                  size="sm"
                  type="text"
                  name="name"
                  v-model="user.name"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Apellido -->
              <b-form-group label="Apellido">
                <validation-provider
                  #default="{ errors }"
                  name="Apellido"
                  rules="max:255"
                >
                <b-form-input
                  size="sm"
                  type="text"
                  name="last_name"
                  v-model="user.last_name"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Telefono -->
              <b-form-group label="Telefono">
                <validation-provider
                  #default="{ errors }"
                  name="Telefono"
                  rules="max_value:20"
                >
                <b-form-input
                  size="sm"
                  type="text"
                  name="phone"
                  v-model="user.phone"
                  :state="errors.length > 0 ? false:null"
                  autocomplete="off"
                ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Roles -->
              <b-form-group
                label="Roles"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Envios Mensuales"
                  rules="required"
                >
                  <b-form-select
                    size="sm"
                    name="roles"
                    v-model="user.roles"
                    :options="roles"
                    :multiple="false"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <footer class="pt-2 px-2">
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 float-right" @click="onSubmit">Guardar</b-button>
          <b-button size="sm" class="mr-1 float-right" variant="outline-primary" @click="close">Cancelar</b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from "../../../services/UserService";
import { required, email, max } from "@validations";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BForm, BFormGroup, BFormInput, BFormSelect, BButton } from 'bootstrap-vue'

export default {
  name: "UserEdit",
  components:{
    BForm, 
    BFormGroup, 
    BFormInput, 
    BFormSelect, 
    BButton,
    ValidationProvider, 
    ValidationObserver
  },
  props: {
    onClose: Object,
    onSave: Object,
    edit: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {
        return {
          id: null,
          email: null,
          name: null,
          password: null,
          c_password: null,
          dni: null,
          phone: null,
          roles: []
        };
      }
    }
  },
  data() {
    return {
      required,
      email,
      max,
      roles: ["admin", "staff", "reseller"]
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$refs.userEdit.validate().then(valid => {
        if (valid) {
          UserService.save(this.user, this.user.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
            })
            .catch(error => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach(key => {
                  response.data.errors[key].forEach(error => {
                    this.veeErrors.add({
                      field: key,
                      msg: error
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
    close() {
      this.$emit("close");
      this.onClose.handler();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
